<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Users</va-card-title>
      <va-card-content>
        <div class="row">
          <va-input
            class="flex mb-2 md6"
            placeholder="Filter..."
            v-model="filter"
          />
        </div>
        <va-data-table
          :items="users"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="setFiltered"
        >
          <template #headerPrepend>
            <th colspan="4" style="font-size: 1rem; text-align: left;">
              <router-link :to="{ name: 'user', params: { id: 'add' }}" class="add">
                + Add User
              </router-link>
            </th>
          </template>
          <template #bodyAppend>
            <tr><td colspan="8" class="table-example--pagination">
              <va-pagination
                v-model="currentPage"
                input
                :pages="pages"
              />
            </td></tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'user', params: { id: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filter.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a user</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load a list of users
      const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, { headers })
      this.users = users.data.users.filter(user => user.uid !== this.uid)
      this.users = await Promise.all(this.users.map((user) => {
        user.name = `${user.first_name} ${user.last_name}`
        this.usersObject[user.uid] = `${user.first_name} ${user.last_name}`
        return user
      }))
      this.filteredCount = this.users.length
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/users' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      uid: this.$store.state.uid,
      users: [],
      usersObject: {},
      columns: [
        {
          key: 'name',
          label: 'Name',
          headerTitle: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          headerTitle: 'Email',
          sortable: true
        },
        {
          key: 'access',
          label: 'Access',
          headerTitle: 'Access',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filter: '',
      filtered: [],
      filteredCount: 0,
      perPage: 10,
      currentPage: 1,
      removeModalShow: false,
      removeId: null,
      removeName: null
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length
    },
  },
  methods: {
    removeModal (id) {
      this.removeId = id
      this.removeName = this.usersObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a user
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/users/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'User deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load list of users again
        const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, { headers })
        this.users = users.data.users.filter(user => user.uid !== this.uid)

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: '/pages/users' } })
      }
      this.loader.hide()
    },
    setFiltered (event) {
      this.filteredCount = event.items.length
      this.filtered = event.items
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }

  .add:hover {
    text-decoration: underline;
  }
</style>
